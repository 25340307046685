export default (state = {}, action) => {
  switch (action.type) {
    case "INIT_MODAL":
      return {
        ...state,
        input: action.input,
      };

    case "TOGGLE_MODAL":
      return {
        ...state,
        show: !state.show,
      };

    default:
      return state;
  }
};
