import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import history from "../../history";
import { goToAround, handleKeyPress, translate } from "../../services/tools";
import {
  UIParcsRelais,
  UIStations,
  UIBicycleParking,
  UIBicycleRental,
  UIParkRide,
  UIParking,
  UIPR,
  UIVCub,
  UIVelonecy,
  UIBicycleSecureParking,
  UIBicycleBollard,
  UIBicycleRepairStation,
  UIPrivateBicycleRental,
  UISwimmingArea,
  UICitiz,
  UILoading,
  UIOpeningHours,
  UIAddress,
  UIEmail,
  UIPhone,
  UIWheelchair,
  UIInfo,
  UICustomText,
  UIDistributeur,
  UIToilet,
  UILocker,
  UIMotorcycleParking,
  UIWaterPoint,
  UIWebsite,
  UIBAV,
  UIHiking,
  UIEntrance,
  UICommercantPartenaire,
} from "./poi/components";
import { PlaceInfos } from "./poi/styled";

const UIPoiContent = (props) => {
  const { place, displayon, options } = props;
  const { placeClicked } = useSelector((state) => state.board);
  const { language, lines, stops } = useSelector((state) => state.app);
  const [placeContent, setPlaceContent] = useState(null);

  function toAround(place) {
    return (
      <div
        className="lc-go-to-around"
        onClick={(e) => {
          e.stopPropagation();
          goToAround(place);
        }}
        onKeyPress={(e) =>
          handleKeyPress(e, () => {
            goToAround(place);
          })
        }
        role="button"
        tabIndex="0"
      >
        <div className="lc-tool-around"></div>
        {translate("title-go-to-around")}
      </div>
    );
  }

  const renderPlaceContent = (place) => {
    switch (place.cat_id) {
      case "poi_type:amenity:parcs_relais":
        setPlaceContent(<UIParcsRelais place={place} />);
        break;
      case "poi_type:stations":
        setPlaceContent(<UIStations place={place} displayon={displayon} />);
        break;
      case "poi_type:amenity:bicycle_parking":
      case "poi_type:PVLO":
      case "poi_type:EquipementVelo":
        setPlaceContent(<UIBicycleParking place={place} />);
        break;
      case "poi_type:amenity:bicycle_rental":
        setPlaceContent(<UIBicycleRental place={place} options={options} />);
        break;
      case "poi_type:amenity:park_ride":
        setPlaceContent(<UIParkRide place={place} />);
        break;
      case "poi_type:amenity:parking":
        setPlaceContent(<UIParking place={place} displayon={displayon} />);
        break;
      case "poi_type:vcub":
        setPlaceContent(<UIVCub place={place} options={options} displayon={displayon} />);
        break;
      case "poi_type:p+r":
        setPlaceContent(<UIPR place={place} options={options} language={language} displayon={displayon} />);
        break;
      case "poi_type:amenity:velonecy":
        setPlaceContent(<UIVelonecy place={place} displayon={displayon} />);
        break;
      case "poi_type:amenity:bicycle_secure_parking":
        setPlaceContent(<UIBicycleSecureParking place={place} displayon={displayon} />);
        break;
      case "poi_type:amenity:bicycle_bollard":
        setPlaceContent(<UIBicycleBollard place={place} displayon={displayon} />);
        break;
      case "poi_type:amenity:bicycle_repair_station":
        setPlaceContent(<UIBicycleRepairStation place={place} displayon={displayon} />);
        break;
      case "poi_type:amenity:private_bicycle_rental":
        setPlaceContent(<UIPrivateBicycleRental place={place} displayon={displayon} />);
        break;
      case "poi_type:leisure:swimming_area":
        setPlaceContent(<UISwimmingArea place={place} displayon={displayon} />);
        break;
      case "poi_type:amenity:car_rental":
      case "poi_type:amenity:citiz":
        setPlaceContent(<UICitiz place={place} displayon={displayon} />);
        break;
      case "poi_type:amenity:motorcycle_parking":
        setPlaceContent(<UIMotorcycleParking place={place} displayon={displayon} />);
        break;
      case "poi_type:101":
      case "poi_type:102":
      case "poi_type:103":
      case "poi_type:104":
        setPlaceContent(<UIDistributeur place={place} displayon={displayon} />);
        break;
      case "poi_type:amenity:toilets":
        setPlaceContent(<UIToilet place={place} displayon={displayon} />);
        break;
      case "poi_type:amenity:locker":
        setPlaceContent(<UILocker place={place} displayon={displayon} />);
        break;
      case "poi_type:amenity:water_point":
        setPlaceContent(<UIWaterPoint place={place} displayon={displayon} />);
        break;
      case "poi_type:amenity:borne_apport_volontaire":
        setPlaceContent(<UIBAV place={place} displayon={displayon} />);
        break;
      case "poi_type:amenity:lieux_touristiques":
        setPlaceContent(<UIHiking place={place} displayon={displayon} />);
        break;
      case "poi_type:entrance":
      case "poi_type:unavailable_entrance":
        setPlaceContent(<UIEntrance place={place} lines={lines} stops={stops} displayon={displayon} />);
        break;
      case "poi_type:9253": // commercants partenaires
        setPlaceContent(<UICommercantPartenaire place={place} />);
        break;
      default:
        // console.warn(`${place.cat_id} is not handle yet.`);
        break;
    }
  };

  useEffect(() => {
    // console.log("place has changed", place);

    if (place) {
      renderPlaceContent(place);
    }
    // eslint-disable-next-line
  }, [place]);

  useEffect(() => {
    // console.log("placeClicked has changed", placeClicked);

    if (placeClicked && placeClicked.id === place.id) {
      renderPlaceContent(placeClicked);
    }
    // eslint-disable-next-line
  }, [placeClicked]);

  if (!place) {
    return null;
  }

  return (
    <PlaceInfos displayon={displayon} data-lc-place-infos>
      <UILoading place={place} displayon={displayon} />
      {placeContent}
      <UIOpeningHours place={place} language={language} />
      <UIAddress place={place} displayon={displayon} />
      <UIEmail place={place} />
      <UIPhone place={place} />
      <UIWebsite place={place} />
      <UIWheelchair place={place} />
      <UIInfo place={place} />
      <UICustomText place={place} displayon={displayon} />
      {displayon === "board" && history.location.pathname !== "/around" && toAround(place)}
    </PlaceInfos>
  );
};

export default UIPoiContent;
