import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { appStore } from "../store";
import { toggleModal } from "../actions/modal";
import { actionBuildAutocomplete } from "../actions/withRedux";
import { handleKeyPress } from "../services/tools";

class Modal extends Component {
  scrollingElement = null;
  modal = null;
  input = null;

  componentDidMount() {
    this.modal = document.querySelector("[data-lc-modal]");
    this.input = this.modal.querySelector("[data-lc-input]");

    // Add active class to modal
    this.modal.classList.add("lc-active");
    this.input.focus();
    this.input.click(); // Fix autocomplete not open
  }

  hide = () => {
    // Add hide class to modal
    this.modal.classList.remove("lc-active");

    setTimeout(() => {
      appStore.dispatch(toggleModal());
    }, 250);
  };

  render() {
    const { input } = this.props;

    if (!process.env.REACT_APP_LIBRARY_URL) {
      // Temporary fix. TODO : change all index.html with a portal ID of lcmap-modal
      document.getElementById("portal") && document.getElementById("portal").setAttribute("id", "lcmap-modal");
    }

    return ReactDOM.createPortal(
      <div className="lc-modal" data-lc-modal>
        <div className="lc-head">
          <div
            className="lc-close"
            onClick={this.hide}
            onKeyPress={(e) => handleKeyPress(e, this.hide)}
            role="button"
            tabIndex="0"
          />
          {appStore.dispatch(actionBuildAutocomplete(input, true))}
        </div>
      </div>,
      document.getElementById("lcmap-modal")
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.modal,
    inputValue: state.board.inputValue,
    inputItems: state.board.inputItems,
    inputStartValue: state.board.inputStartValue,
    inputStartItems: state.board.inputStartItems,
    inputEndValue: state.board.inputEndValue,
    inputEndItems: state.board.inputEndItems,
  };
};

export default connect(mapStateToProps)(Modal);
