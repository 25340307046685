export default (state = {}, action) => {
  switch (action.type) {
    case "SET_TOURISM_PARTNER_SELECTED_TYPE":
      return {
        ...state,
        typePartner: action.typePartner,
      };

    case "SET_TOURISM_PARTNER_SELECTED_PARTNER":
      return {
        ...state,
        partner: action.partner,
      };

    default:
      return state;
  }
};
